
import { computed, defineComponent, onMounted } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components";
import UserType from "@/components/layout/UserType.vue";
import store from "@/store";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "dashboard",
  components: { UserType },
  setup() {
    const router = useRouter();

    const getCurrentPages = computed(() => {
      return store.getters.menuConfig.filter((item) => item.belong !== "all");
    });

    const getFirstPath = computed(() => {
      return (pages: any[], path = "") => {
        if (pages.length > 0) {
          const fistObj = pages[0];
          if (
            fistObj.belong &&
            process.env.VUE_APP_SAAS_SYSTEM !== fistObj.belong
          ) {
            path += fistObj.base_url + "/#";
          }
          if (fistObj.pages) {
            path = getFirstPath.value(fistObj.pages, path);
          } else {
            path += fistObj.route;
          }
        }
        return path;
      };
    });

    const goDefaultPath = () => {
      if (store.getters.currentUser.user_type === 1) {
        const pages = getCurrentPages.value;
        if (pages.length > 0) {
          const path = getFirstPath.value(pages);
          console.log(path);
          if (path) {
            if (path.indexOf("/#") > -1) {
              window.location.href = path;
            } else {
              router.replace(path);
            }
          }
        }
      }
    };

    onMounted(() => {
      setCurrentPageTitle("Overview");
      MenuComponent.reinitialization();
      goDefaultPath();
    });
  },
});
