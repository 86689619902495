
import store from "@/store";
import { computed, defineComponent } from "vue";

export default defineComponent({
  name: "user-type",
  props: {
    type: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const checkUserType = computed(() => {
      return props.type === store.getters.currentUser.user_type;
    });

    return {
      checkUserType,
    };
  },
});
